
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SalesRepConsolidateTable extends ViewModel {
  @Prop({ default: false })
  public footClone!: boolean

  @Prop()
  public subHeaderFields!: any

  @Prop()
  public headerFields!: any

  @Prop()
  public rows!: any

  @Prop({ default: {} })
  public tableBinding!: any
}
